import styled from 'styled-components';
import { Link } from 'react-router-dom'


const GRAY = '#efefef';

export const H1 = styled.h1` 
font-size: 2rem;
letter-spacing: ${props => props.weight === "bolder" ? "-0.05em" : "-0.05em"};
font-weight: ${props => props.weight === "bolder" ? 800 : 500};
margin: ${props => props.margin  ? "0 0 1rem" : "0"};
    a {
    font-weight: ${props => props.weight === "bolder" ? 800 : 500};
    letter-spacing: ${props => props.weight === "bolder" ? "-.01em" : "0.05em"};
    }
  @media screen and (min-width: 768px) {
    font-size: 3rem;
    margin: ${props => props.margin  ? "2rem 0" : "0"};
  }
`

export const H2 = styled.h2`
    font-size: 4.5rem;
    letter-spacing: -0.05rem;
    margin-bottom: 1rem;
    @media screen and (min-width: 768px) {
        font-size: 8rem;
        letter-spacing: -.05em;
        line-height: 8rem;
        margin-bottom: 3rem;
    }
`;

export const H3 = styled.h3`
    font-size: 3rem;
    letter-spacing: -0.05rem;
    color: #c5c5c5;
`

export const H4 = styled.h4`
    font-size: 3rem;
    letter-spacing: -0.05em;
    color: black;
`

export const TextLarger = styled.div`
    font-size: 5rem;
    letter-spacing: -0.2rem;
    line-height: 8rem;
    margin-bottom: 3rem;
`

export const TextLarge = styled.div`
    font-size: 3rem;
    letter-spacing: -0.05em;
    @media screen and (min-width: 768px) {
        font-size: 5rem;
        line-height: 5rem;
    }
`
export const BodyCopy = styled.div`
    font-size: 2rem;
    line-height: 1.5em;
    font-weight: 400;
    color: ${props => props.color};
    @media screen and (min-width: 768px) {
        font-size: 2rem;
        line-height: 1.5em;
        letter-spacing: 0px;
    }
`;

export const GridContainer = styled.div`
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr;
    @media screen and (min-width: 1024px) {
        grid-template-columns: ${props => props.col === "2" ? '1fr 1fr' : "1fr"};
    }
`;

export const Main = styled.main`
    min-height: calc(100vh - 200px);
    display: ${props=>props.grid ? "grid" : "block"};
    &.container {
        max-width: 768px;
        margin: 1rem auto;
    }
    ${props=> props.cols &&
        `
            grid-template-columns: ${props.cols};
        `
    }
    ${props=> props.gap &&
        `
            gap: ${props.gap}rem;
        `
    }
    @media screen and (max-width: 768px) {
        &.container {
            margin: 1rem 3rem;
        }
        ${props=> props.cols &&
            `
                grid-template-columns: 1fr;
            `
        }
    }
`

export const MenuItem = styled.a`
    font-size: 1.5rem;
    color: black;
    text-decoration: none;
    text-transform: capitalize;
    margin: 1rem;
    font-weight: 500;
    letter-spacing: -.4px;

    @media screen and (min-width: 768px) {
        font-size: 2rem;
        letter-spacing: -1px;
    }
`;


export const Button = styled.button`
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height:4rem;
  padding: 4px 2rem;
  font-size: 1.5rem;
  border-radius: 24px;
  background: black;
  border: 1px solid #434343;
  color: white;
  a {
      color: white;
      font-size: inherit;
  }
  &:hover {
      background-color: white;
      color: black;
      a {
          color: black;
        }
  }
  &.center {
      margin: 0 auto;
  }
  @media screen and (min-width: 768px) {
    font-size: 2rem;
  }
`

export const ButtonCTA = styled(Button)`
    height: 5rem;
    padding: 0rem 2.5rem;
    font-size: 2.5rem;
    margin-top: 3rem;
    border-radius: 2em;
    @media screen and (min-width: 768px) {
        height:6.5rem;
        padding: .7rem 2.5rem;
        font-size: 3rem;
        margin-top: 3rem;
        border-radius: 2em;
    }
`;


// Form Items
export const Input = styled.input`
    border: 0;
    width: 100%;
    height: 100%;
    padding: 2rem 2.5rem;
    background: white;
    border-radius: 3px;
    box-shadow: 0px 8px 15px rgb(75 72 72 / 10%);
    transition: all 0.4s ease;
    font-size: 3    rem;
    font-weight: bolder;
    color: black;
    border: 1px solid ${GRAY};
    
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: ${GRAY};
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: ${GRAY};
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: ${GRAY};
    }
    :-moz-placeholder { /* Firefox 18- */
        color: ${GRAY};
    }
    &:focus {
        outline: 0;
        box-shadow: 0px 8px 18px  rgb(75 72 72 / 40%);
    }
    &:disabled {
        text-align: center;
        box-shadow: unset;
        border:0;
    }
`

export const Form = styled.form`
    padding: 0;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    border-radius: 3px;
    label {
        display: inline-block;
        margin-bottom: 0.5rem;
        font-size: 2rem;
        text-transform: uppercase;
        touch-action: manipulation; 
        position: absolute;
        left: -999999999px;
    }
    fieldset {
        border: 0;
    }
`

// CHART
export const ChartWrapper = styled.section`
    min-height: 50vh;
`;


// NFTS 
export const SavedContainer = styled.div`
    h3 {
        border-bottom: 1px solid #efefef;
        padding: 1rem 0;
    }
    img {
        width: 100%;
        max-width:300px;
    }
    .saved-nft-inner {
        margin: 1rem;
        border-bottom: 1px solid #efefef;
        padding: 1rem 0;
        text-align: center;
    }
    box-shadow: 0px 8px 15px rgb(75 72 72 / 10%);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    border: 1px solid #efefef;
    padding: 2rem;
`;