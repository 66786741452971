import { useEffect, useState } from 'react'

import savedSpheres, { SavedSpheres } from '../components/create/savedSpheres';

import { H1, BodyCopy, Input, Form, ChartWrapper, Button, Main, SavedContainer } from '../styles'
import { Pie } from 'react-chartjs-2';

import { colorMap, colorMapLower, colorMapTwo} from '../utils/colors';

let nextColor = 0;
var clickEvent = new MouseEvent("click", {
    "view": window,
    "bubbles": true,
    "cancelable": false
});

const options = {
    maintainAspectRatio: false,
    onClick: function(evt, activeElement) {
        if(activeElement && activeElement.length > 0) {
            const datainfo = evt.chart.config._config.data;
            const letter = datainfo.labels[activeElement[0].index];
                if (colorMapTwo[letter]) {
                    if (nextColor < colorMapTwo[letter].length) {
                        nextColor++;
                    } else {
                        nextColor = 0;
                    }
                    this.config._config.data.datasets[0].backgroundColor[activeElement[0].index] = colorMapTwo[letter][nextColor];
                    let legendLabel = document.getElementById(`${letter}`).style.backgroundColor = colorMapTwo[letter][nextColor];
                }
                
            } 

        const canvas = document.querySelector("canvas");
        canvas.dispatchEvent(clickEvent);
    },

    plugins: {
        tooltip: {
          // Tooltip will only receive click events
          enabled: false,
          callbacks: {
            label: function(context) {
                var label = context.label || '';
                return label;
            }
            }
        },
        legend: {
            display: false,
        },
      },
    states: {
        hover: {
          halo: {
            opacity: 1
        }
      }
    }
  }

const PieChart = ({ data }) => {
    return <>
        <Pie
            data={data}
            width={300}
            height={300}
            options={options}
        />
    </>
};

export default function Create() {
    const [value, setValue] = useState("");
    const [chartCreated, setChartCreated] = useState(false);
    const [chartData, setChartData] = useState({});
    const [nftCache, setNFTCache] = useState([]);
    const [legend, setLegend]= useState([]);

    const parseChartData = async () => {
        const dataColors = [];
        let splitArr = value.split(' ');
        splitArr = splitArr.reverse();
        let legend = {}
        for (let word of splitArr) {
            const lettersToArr = Array.from(word.split(''), x => {
                return 1
            });
            const dataSetForWord = {
                data: lettersToArr,
                backgroundColor: [],
                borderWidth: 0,
                key: word,
                label: word
            }
            
            
             for (let letter of word) {
                // letter = letter.toLowerCase();
                let color = colorMapTwo[letter][0] ? colorMapTwo[letter][0] : colorMapTwo[letter]
                dataSetForWord.backgroundColor.push(color);
                legend[letter] = color;
            }

            dataColors.push(dataSetForWord)
        }

        setChartData({
            ...chartData,
            datasets: dataColors,   
            labels: splitArr.join('').split('')
        });

        legend = Object.entries(legend);
        setLegend(legend);
        setChartCreated(true);

    }

    const _onSubmit = (e) => {
        e.preventDefault();
        parseChartData();
    }

    const _restart = () => {
        const canvas = document.querySelector("canvas");
        const img    = canvas.toDataURL("image/png");
        const savedNft = {
            text: value,
            image: img
        }
        setNFTCache([...nftCache, savedNft]);
        setChartCreated(false);
        setLegend([]);

    }

    const _save = () => {
        const canvas = document.querySelector("canvas");
        const img    = canvas.toDataURL("image/png");
        const savedNft = {
            text: value,
            image: img
        }
        setNFTCache([...nftCache, savedNft]);
    }
  
    return (
        <Main className="m-t-0 m-l-3 m-r-3" grid gap={2} cols="1fr 4fr">
            <SavedSpheres nftCache={nftCache}/>
            <section className="create-section">
                <div>
                    <Form onSubmit={_onSubmit}>
                        <fieldset>
                            <label htmlFor="colorsphere">Add your phrase</label>
                            <Input disabled={chartCreated ? true : false} onChange={(e) => setValue(e.target.value)} type="search" id="colorsphere" name="colorsphere" placeholder="Add your phrase" />
                        </fieldset>
                    </Form>
                </div>
                <div style={{display: 'flex', justifyContent: "center"}}>
                    {legend.map((el,i )=> {
                        return <span style={{margin: '0 20px'}} >
                            <span className="legendcolor" id={el[0]} style={{display: 'inline-block', backgroundColor: el[1], height: "20px", width: "20px"}}></span> <b>{el[0]}</b>
                        </span>
                    })}
                </div>
                <ChartWrapper className="m-t-0 chart">
                    {chartCreated &&
                        <PieChart data={chartData} />
                    }
                </ChartWrapper>
                {chartCreated &&
                    <>
                        <div className="text-align-center " >
                            <Button className="p-side-3">Mint Me</Button>
                            {/* <Button onClick={_save} className="center p-3">Save</Button> */}
                            <Button onClick={_restart} className="p-side-3">Create Another</Button>
                        </div>
                    </>
                }
           </section>
        </Main>
    );
}