
import {
    GridContainer,
    H1,
    H2,
    H3,
    H4,
    TextLarge,
    MenuItem,
    Button,
    ButtonCTA,
    BodyCopy,
    ChartWrapper,
    Main
} from '../styles'
import { Pie } from 'react-chartjs-2';

import { Link } from 'react-router-dom';

const data = {
    datasets: [
        {
            data: [4, 3, 5, 2, 3],
            backgroundColor: [
                '#0066CC',
                '#0099FF',
                '#CC99CC',
                '#FF6699',
                '#3366FF',
            ],
            borderWidth: 0,
            responsive: true
        },
    ],
    options:{
        tooltips:{
            enabled:false
          },
        hover: {
            mode: null
        },
        tooltips: false,
        events: ["mouseout", "click", "touchstart", "touchmove", "touchend"],
        aspectRatio: true,
        maintainAspectRatio: true,
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        responsive: true
    }
};

function App() {
    return (
        <div className="App">
            <Main>
                <GridContainer col="2" className="m-t-3 m-l-3 m-r-3">
                    <div>
                        <H2>Colorsphere</H2>
                        {/* <h3 className="text-large">
                            <TextLarge>
                                Turn any text into a unique NFT chromosphere
                            </TextLarge>
                        </h3>
                        <ButtonCTA>
                            <Link to="create">Create a sphere</Link>
                        </ButtonCTA> */}
                        <H4 className="text-large m-t-6">What is Colorsphere?</H4>
                        <BodyCopy color="#a1a1a5">
                            <p className="m-t-1">
                                Colorsphere is a generative NFT platform that plays 
                                with the dynamics of light, language, and technology.
                                Our names, thoughts, dates, quotes, confessions, and snippets of stories, 
                                translated into a visual language of digital color.
                                A wheel of ciphered hues, yours to own or trade, 
                                minted forever on the blockchain.
                            </p>
                        </BodyCopy>
                    </div>
                    <ChartWrapper className="responsive-image" style={{pointerEvents: 'none'}}>
                        <Pie
                            data={data}
                            width={"100%"}
                            height={"100%"}
                            options={{ maintainAspectRatio: true, tooltips: false }}
                        />
                    </ChartWrapper>

                </GridContainer>
            </Main>
        </div >
    );
}

export default App;
