import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, NavLink, Link, Switch, Route } from 'react-router-dom'
import { GridContainer, H1, H2, H3, TextLarge, MenuItem, Button, ButtonCTA } from './styles'

import Home from './pages/Home';
import About from './pages/About';
import FAQ from './pages/FAQ';
import Create from './pages/Create';

function App() {
  return (
    <Router>
      <div className="comingsoon">
        <h1>COMING SOON</h1>
        <div>
          <form
            action="https://formspree.io/f/moqyzgzo"
            method="POST"
          >
            <p>
              <label>Your email:  </label>
              <input type="email" placeholder="Your Email *" name="_replyto"/>
            </p>
            <p>
            <label>Your name: </label>
            <input type="text"  placeholder="Your Name"  name="name"/>
          </p>
            <p>
              <label>Your Discord Handle: </label>
              <input type="text" placeholder="Discord Handle"  name="discord"/>
           </p>
           
          <p>
            <label>Your Twitter Handle:</label>
              <input type="text" placeholder="Twitter Handle" name="twitter"/>
            </p>
            <p> 
            <label>Your Wallet Address: </label> 
              <input type="text" placeholder="Wallet Address"  name="wallet"/>
           
            </p>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
      <header className="App-header bottom-border">
        
        <nav className="flex-container space-between">
          <H1 weight="bolder">
            <Link to="/">Colorsphere</Link>
          </H1>
          <div className="flex-container">
            <ul className="flex-container nav-items">
              <li className="desktop-only">
                <NavLink to="about">about</NavLink>
              </li>
              <li>
                <NavLink to="create">create</NavLink>
              </li>
              <li  className="desktop-only">
                <NavLink to="faqs">faq</NavLink>
              </li>
            </ul>
            <Button>Meta Mask</Button>
          </div>
        </nav>
      </header>
      <Switch>
        <Route path="/" exact component={Home} />
        {/* <Route path="/about" component={About} /> */}
        {/* <Route path="/faqs" component={FAQ} /> */}
        {/* <Route path="/create" component={Create} /> */}
      </Switch>
      <footer className="border-top">
        <ul className="flex-container flex-end">
          <li>
            <a href="about">about</a>
          </li>
          <li>
            <a href="create">create</a>
          </li>
          <li>
            <a href="terms-and-conditions">Terms & conditions</a>
          </li>
        </ul>
      </footer>
    </Router>
  );
}

export default App;
